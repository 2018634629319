import { graphql } from "gatsby"
import React from "react"
import TileLayout from "../../layouts/TileLayout"
import DatoBlock, { DatoBlockProps } from "../DatoBlock"

type DatoTileGridProps = Queries.DatoTileGridFragment

const DatoTileGrid = (props: DatoTileGridProps) => {
  // console.debug("Rendering TileGrid", props)
  if (!props.contentModules) {
    return null
  }
  return (
    <TileLayout>
      {props.contentModules.map(block => (block ? <DatoBlock key={block.key} {...(block as DatoBlockProps)} /> : null))}
    </TileLayout>
  )
}

export default DatoTileGrid

export const query = graphql`
  fragment DatoTileGrid on DatoCmsTileGrid {
    id: originalId
    emphasizeFirst
    contentModules {
      ... on Node {
        key: id
        __typename
      }
      ... on DatoCmsHighlightToExternalUrl {
        ...DatoHighlightToExternalUrl
      }
      ... on DatoCmsCtaToPage {
        ...DatoCtaToPage
      }
      ... on DatoCmsCtaToExternalUrl {
        ...DatoCtaToExternalUrl
      }
      ... on DatoCmsCtaToProduct {
        ...DatoCtaToProduct
      }
      ... on DatoCmsTextBlock {
        ...DatoTextBlock
      }
      ... on DatoCmsCtaRef {
        ...DatoCtaRef
      }
      ... on DatoCmsHighlightToPage {
        ...DatoHighlightToPage
      }
      ... on DatoCmsHighlightToProduct {
        ...DatoHighlightToProduct
      }
      ... on DatoCmsMediaWithText {
        ...DatoMediaWithText
      }
      ... on DatoCmsMediaBlock {
        ...DatoMediaBlock
      }
      ... on DatoCmsExternalVideo {
        ...DatoExternalVideo
      }
      ... on DatoCmsVideoWithText {
        ...DatoVideoWithText
      }
      ... on DatoCmsCtaToArticle {
        ...DatoCtaToArticle
      }
      ... on DatoCmsContactLocationBlock {
        ...DatoContactLocationBlock
      }
      ... on DatoCmsIframeBlock {
        ...DatoIframeBlock
      }
      ... on DatoCmsHighlightToArticle {
        ...DatoHighlightToArticle
      }
      ... on DatoCmsHighlightToProductFinder {
        ...DatoHighlightToProductFinder
      }
      ... on DatoCmsCtaToProductFinder {
        ...DatoCtaToProductFinder
      }
    }
  }
`
